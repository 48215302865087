import React from 'react'

import { LinkOutsideIcon } from 'components/SVG'

import classes from './styles.module.scss'

export const Button = ({ href, isExternal, children }) => {
  const renderTag = () => {
    if (href) {
      return (
        <a
          className={classes.wrapper}
          href={href}
          {...(isExternal ? { target: '_blank', rel: 'noopener noreferrer' } : null)}
        >
          {children}
          {isExternal ? <LinkOutsideIcon /> : null}
        </a>
      )
    } else {
      return <div className={classes.wrapper}>{children}</div>
    }
  }
  return <div className={classes.buttonContainer}>{renderTag()}</div>
}

export default Button
