import { combineReducers } from "redux";

import diamondsReducer from "./diamonds";
import versionReducer from "./version";

const rootReducer = () =>
  combineReducers({
    diamonds: diamondsReducer,
    version: versionReducer,
  });

export default rootReducer;
