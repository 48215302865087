import React from 'react'

import classes from './styles.module.scss'

export const Footer = ({ testIdPrefix }) => {
  return (
    <div className={classes.footerContainer} data-test-id={testIdPrefix + 'footer'}>
      <footer>
        <div className={classes.wrapper}>
          <div className={classes.gemfairLogo}>
            <a href="https://gemfair.com/" target="_blank" rel="noopener noreferrer">
              <img src={'/images/logos/gemfair_white.svg'} alt="Gemfair logo" />
            </a>
          </div>
          <div className={classes.socialMedia}>
            <a
              className={classes.linkToGemfair}
              href="https://www.gemfair.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              GemFair
            </a>
            <span>follow us</span>
            <ul className={classes.links}>
              <li>
                <a href="https://www.linkedin.com/company/gemfair" target="_blank" rel="noopener noreferrer">
                  <img src={'/images/logos/linkedin.svg'} alt="Link to linkedIn" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/gemfair/" target="_blank" rel="noopener noreferrer">
                  <img src={'/images/logos/instagram.svg'} alt="Link to instagram " />
                </a>
              </li>
            </ul>
          </div>
          <div className={classes.linksGroup}>
            <ul className={classes.links}>
              <li>
                <a href="https://www.debeersgroup.com/" target="_blank" rel="noopener noreferrer">
                  De Beers Group
                </a>
              </li>
              <li>
                <a href="https://www.tracr.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">
                  Privacy policy
                </a>
              </li>
            </ul>
          </div>
          <div className={classes.linksGroup}>
            <ul className={classes.links}>
              <li>
                <a href="https://www.tracr.com/" target="_blank" rel="noopener noreferrer">
                  Tracr
                </a>
              </li>
              <li>
                <a href="https://www.tracr.com/legal/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                  Terms and conditions
                </a>
              </li>
            </ul>
          </div>
          <div className={classes.rightsAndCreds}>
            <div className={classes.poweredBy}>
              <span>Powered by</span>
              <a href="https://www.tracr.com/" target="_blank" rel="noopener noreferrer">
                <img src={'/images/logos/tracr.svg'} alt="Tracr logo" />
              </a>
            </div>
            <div className={classes.copyright}>
              &copy; GEMFAIR LIMITED 2024 <br />
              (A MEMBER OF DE BEERS GROUP)
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
