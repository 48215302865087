const testIds = {
  landingPage: {
    searchTop: 'landing-search-top',
    searchBottom: 'landing-search-bottom',
    header: 'header',
    section: 'section',
    sectionScoller: 'scroller',
    footer: 'lading',
  },
}

export default testIds
