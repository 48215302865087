import React from 'react'
import { pathOr } from 'ramda'

import { valueToSVG } from 'utils'

import classes from './styles.module.scss'

export const Row = ({ path, diamond }) => {
  const renderProp = () => {
    const value = pathOr(null, path.split('.'), diamond)

    if (value === null) return

    if (value.image) {
      return (
        <div className={classes.wrapper} key={path}>
          <div className={classes.key}>{value.title}</div>
          <div className={classes.value}>
            <div className={classes.icon}>{valueToSVG(value.image)}</div>
            {value.value}
          </div>
        </div>
      )
    } else {
      return (
        <div className={classes.wrapper} key={path}>
          <div className={classes.key}>{value.title}</div>
          <div className={classes.value}>{value.value}</div>
        </div>
      )
    }
  }

  return (
    <div className={classes.rowContainer} data-test-id="diamond-information-row">
      {renderProp()}
    </div>
  )
}

export default Row
