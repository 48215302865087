import React from 'react'
import classnames from 'classnames'

import classes from './styles.module.scss'

export const Heading = ({ type = 'h3', color = 'dark', className = '', children }) => {
  if (!children) return null

  switch (type) {
    case 'h1':
      return (
        <h1 className={classnames(classes.headingContainer, className, { [classes.white]: color === 'white' })}>
          {children}
        </h1>
      )
    case 'h2':
      return (
        <h2 className={classnames(classes.headingContainer, className, { [classes.white]: color === 'white' })}>
          {children}
        </h2>
      )

    case 'h3':
      return (
        <h3 className={classnames(classes.headingContainer, className, { [classes.white]: color === 'white' })}>
          {children}
        </h3>
      )

    case 'h4':
    default: {
      return (
        <h4 className={classnames(classes.headingContainer, className, { [classes.white]: color === 'white' })}>
          {children}
        </h4>
      )
    }
  }
}

export default Heading
