import React, { useRef, useState, useEffect } from 'react'
import classnames from 'classnames'

import classes from './styles.module.scss'

export const HorizontalScroller = ({ className, children }) => {
  const containerRef = useRef()
  const selectorRef = useRef()
  const [dragStartX, setDragStartX] = useState(0)

  const getPaddingValue = (node) => {
    return +window.getComputedStyle(node, null).getPropertyValue('padding-left').replace('px', '')
  }

  const setContainerRef = (node) => {
    if (!node) return null

    containerRef.current = node
  }

  const handleTouchStart = (evt) => {
    const currentX = selectorRef.current.getBoundingClientRect().x - getPaddingValue(containerRef.current)

    setDragStartX(evt.touches[0].clientX - currentX)
  }

  const handleTouchMove = (evt) => {
    // disable touch event when enough space for tabs is available
    if (selectorRef.current.offsetWidth > selectorRef.current.children[0].offsetWidth) return

    const maxTravelDistanceX = selectorRef.current.children[0].offsetWidth - selectorRef.current.offsetWidth
    const newX = evt.touches[0].clientX - dragStartX

    if (newX > 0) {
      selectorRef.current.style.transform = `translateX(0)`
    } else if (newX * -1 > maxTravelDistanceX) {
      selectorRef.current.style.transform = `translateX(-${maxTravelDistanceX}px)`
    } else {
      selectorRef.current.style.transform = `translateX(${newX}px)`
    }
  }

  useEffect(() => {
    const onResize = () => {
      if (selectorRef.current.offsetWidth <= selectorRef.current.children[0].offsetWidth) {
        selectorRef.current.style.transform = null
      }
    }

    onResize()

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <div className={classnames(classes.horizontalScroller, className)} ref={setContainerRef}>
      <div className={classes.wrapper}>
        <div
          className={classes.selector}
          ref={selectorRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default HorizontalScroller
