import React from 'react'

export const MagnifyingGlassIcon = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.09919 0.852949C5.5687 -0.0518879 1.90969 2.0596 0.926554 5.56909C-0.0565813 9.07857 2.00845 12.6571 5.53894 13.5619C7.23455 13.9965 8.95981 13.7353 10.404 12.9576L13.5087 16.9761C13.8427 17.4084 14.4732 17.4843 14.9168 17.1456C15.3605 16.8069 15.4494 16.1818 15.1154 15.7495L12.0231 11.7471C12.801 10.961 13.395 9.97587 13.7116 8.84579C14.6947 5.3363 12.6297 1.75779 9.09919 0.852949ZM2.86394 6.06562C3.54911 3.61976 6.09919 2.1482 8.55969 2.77881C11.0202 3.40942 12.4594 5.90339 11.7742 8.34926C11.089 10.7951 8.53894 12.2667 6.07844 11.6361C3.61795 11.0055 2.17876 8.51149 2.86394 6.06562Z"
        fill="#2B2B2B"
      />
    </svg>
  )
}

export const CloseIcon = () => {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.1291 1.23042C1.73858 0.839899 1.10542 0.839899 0.714891 1.23042C0.324367 1.62095 0.324367 2.25411 0.714891 2.64464L5.07029 7.00003L0.714922 11.3554C0.324397 11.7459 0.324397 12.3791 0.714922 12.7696C1.10545 13.1601 1.73861 13.1601 2.12914 12.7696L6.4845 8.41424L10.8399 12.7696C11.2304 13.1601 11.8636 13.1601 12.2541 12.7696C12.6446 12.3791 12.6446 11.7459 12.2541 11.3554L7.89871 7.00003L12.2541 2.64464C12.6446 2.25411 12.6446 1.62095 12.2541 1.23042C11.8636 0.839898 11.2304 0.839899 10.8399 1.23042L6.4845 5.58582L2.1291 1.23042Z"
        fill="#2B2B2B"
      />
    </svg>
  )
}

export const ScrollDownIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15.25" stroke="#2B2B2B" strokeWidth="1.5" />
      <path
        d="M10 13.333L16 19.333L22 13.333"
        stroke="#2B2B2B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const LinkOutsideIcon = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.42187 1H10.9237L5.95007 5.94858L6.6554 6.65746L11.6351 1.70282V5.18951H12.6351V0.5C12.6351 0.223858 12.4112 0 12.1351 0H7.42187V1ZM1 1.75781C0.723858 1.75781 0.5 1.98167 0.5 2.25781V11.6368C0.5 11.913 0.723858 12.1368 1 12.1368H10.4264C10.7025 12.1368 10.9264 11.913 10.9264 11.6368V6.00098H9.9264V11.1368H1.5V2.75781H6.5V1.75781H1Z"
        fill="white"
      />
    </svg>
  )
}
