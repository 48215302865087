import { SierraLeoneIcon } from 'components/SVG'

export const valueToSVG = (provenance) => {
  const lowerCased = provenance ? provenance.toLowerCase() : ''

  switch (lowerCased) {
    case 'sierra_leone': {
      return <SierraLeoneIcon />
    }
    default:
      return <SierraLeoneIcon />
  }
}

export default valueToSVG
