import React from 'react'
import classnames from 'classnames'

import classes from './styles.module.scss'

export const Paragraph = ({ className = '', children }) => {
  if (!children) return null

  return <p className={classnames(classes.paragraphContainer, className)}>{children}</p>
}

export default Paragraph
