import React, { useRef, useState, useEffect } from 'react'
import classnames from 'classnames'

import { CloseIcon, MagnifyingGlassIcon } from 'components/SVG'

import classes from './styles.module.scss'

export const Search = ({
  value: initValue = '',
  placeholder = 'Search by Tracr ID',
  theme = 'default',
  isInvalid = false,
  wrapperClassName = '',
  testIdPrefix = '',
  onSubmit,
  onClear,
  children,
}) => {
  const containerRef = useRef()
  const inputRef = useRef()
  const [value, setValue] = useState(initValue === null ? '' : initValue)
  const [isFocused, setIsFocused] = useState(false)
  const [isInvld, setIsInvld] = useState(isInvalid)

  const handleChange = () => {
    setValue(inputRef.current.value)
    setIsInvld(false)
  }

  const handleClear = () => {
    setValue('')
    setIsInvld(false)

    if (onClear) onClear()

    inputRef.current.focus()
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleKeyDown = (evt) => {
    if (!isFocused) return

    if (evt.keyCode === 13) handleSubmit()
  }

  const handleSubmit = () => {
    const value = inputRef.current.value

    if (value.replace(/^\s|\s$/g, '') === '') return

    if (onSubmit) onSubmit(value)

    inputRef.current.focus()
  }

  useEffect(() => {
    const listener = (evt) => {
      const target = evt.target

      if (containerRef.current.contains(target) || containerRef.current === target) return
      else setIsFocused(false)
    }

    window.addEventListener('click', listener)

    return () => {
      window.removeEventListener('click', listener)
    }
  }, [])

  useEffect(() => {
    setValue(initValue === null ? '' : initValue)
  }, [initValue])

  useEffect(() => {
    setIsInvld(isInvalid)
  }, [isInvalid])

  return (
    <div
      className={classes.searchContainer}
      ref={containerRef}
      onFocus={handleFocus}
      onClick={handleFocus}
      onKeyDown={handleKeyDown}
    >
      <div
        className={classnames(classes.wrapper, wrapperClassName, {
          [classes.focused]: isFocused,
          [classes.invalid]: isInvld,
          [classes.dark]: theme === 'dark',
        })}
      >
        <input
          type="text"
          value={value}
          placeholder={placeholder}
          className={classnames({ [classes.dark]: theme === 'dark' })}
          ref={inputRef}
          onChange={handleChange}
          data-test-id={testIdPrefix + 'search-input'}
        />
        <div
          className={classnames(classes.buttonClear, {
            [classes.visible]: value !== '',
            [classes.dark]: theme === 'dark',
          })}
          onClick={handleClear}
          data-test-id={testIdPrefix + 'search-clear'}
        >
          <CloseIcon />
        </div>
        <div
          className={classnames(classes.buttonSubmit, {
            [classes.disabled]: isInvld,
            [classes.dark]: theme === 'dark',
          })}
          onClick={handleSubmit}
          data-test-id={testIdPrefix + 'search-submit'}
        >
          <MagnifyingGlassIcon />
        </div>
      </div>
      {children ? <div className={classes.children}>{children}</div> : null}
    </div>
  )
}

export default Search
