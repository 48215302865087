import React from 'react'

import Row from './Row'
import classes from './styles.module.scss'

export const DataGrid = ({ diamond }) => {
  const propertiesToRender = [
    'supplementary_tags.diamond_information.tracr_id',
    'supplementary_tags.diamond_information.location',
    'supplementary_tags.diamond_information.carats',
    'supplementary_tags.diamond_information.discovered_on',
    'supplementary_tags.diamond_information.coordinates',
    'supplementary_tags.diamond_information.miner',
    'supplementary_tags.diamond_information.name_of_site',
    'supplementary_tags.diamond_information.date_purchased',
    'supplementary_tags.diamond_information.qr_code',
    'supplementary_tags.diamond_information.buying_office_location',
    'supplementary_tags.diamond_information.export_date',
    'supplementary_tags.diamond_information.kimberly_process',
    'supplementary_tags.diamond_information.export_location',
    'supplementary_tags.diamond_information.date_of_sale',
    'supplementary_tags.diamond_information.auction_cycle_number',
    'supplementary_tags.diamond_information.auction_location',
    'supplementary_tags.diamond_information.fluorescence',
  ]

  return (
    <div className={classes.dataGridContainer}>
      <div className={classes.wrapper}>
        <h3 className={classes.title}>Diamond information</h3>
        <div className={classes.grid}>
          {propertiesToRender.map((path) => {
            return <Row path={path} diamond={diamond} key={path} />
          })}
        </div>
      </div>
    </div>
  )
}

export default DataGrid
