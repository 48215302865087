import React, { useRef } from 'react'
import classnames from 'classnames'

import DataGrid from '../DataGrid'
import Footnote from '../Footnote'
import classes from './styles.module.scss'

export const Tab = ({ tab, diamond, isActive, onTransitionComplete }) => {
  const tabRef = useRef()

  const onRefSet = (node) => {
    if (!node) return

    tabRef.current = node

    node.addEventListener('transitionend', onTransitionComplete)
  }

  return (
    <div className={classnames(classes.tabContainer, { [classes.hide]: !isActive })} ref={onRefSet}>
      <div className={classes.wrapper}>
        <div className={classes.description} data-test-id="tab-description">
          <h3 className={classes.title}>{tab.copy_header_text}</h3>
          {tab.copy_text
            .split('\n')
            .filter((copy) => copy)
            .map((copy, index) => {
              return (
                <p className={classes.copy} key={'copy' + index}>
                  {copy}
                </p>
              )
            })}
        </div>
        <div className={classes.dataGrid}>
          <DataGrid diamond={diamond} />
        </div>
        <div className={classes.footnote}>
          <Footnote diamond={diamond} />
        </div>
      </div>
    </div>
  )
}

export default Tab
