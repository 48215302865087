import React, { useState, useEffect } from 'react'
import { pathOr } from 'ramda'

import classes from './styles.module.scss'

export const Footnote = ({ diamond }) => {
  const [notes, setNotes] = useState(null)

  useEffect(() => {
    setNotes(pathOr(null, ['supplementary_tags', 'footnote'], diamond))
  }, [JSON.stringify(diamond)]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!notes) return null

  return (
    <div className={classes.footnoteContainer}>
      <div className={classes.wrapper}>
        <div className={classes.border}></div>
        <ul className={classes.notes}>
          {notes.map((note) => {
            return <li>{note}</li>
          })}
        </ul>
      </div>
    </div>
  )
}

export default Footnote
