import React from 'react'
import classnames from 'classnames'

import classes from './styles.module.scss'

export const NotFoundMSG = ({
  title = 'Sorry, we can’t find that diamond',
  theme = 'dark',
  searchId = '',
  className = '',
}) => {
  return (
    <div className={classnames(classes.notFoundMSGContainer, className, { [classes.light]: theme === 'light' })}>
      <div className={classnames(classes.title, { [classes.light]: theme === 'light' })}>{title}</div>
      <div className={classnames(classes.description, { [classes.light]: theme === 'light' })}>
        The Tracr ID <span>{searchId}</span> could be private or may not exist
      </div>
    </div>
  )
}

export default NotFoundMSG
