import React from 'react'

export const SierraLeoneIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.5 16.125H24.5V24H0.5V16.125Z" fill="#498BEC" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.5 7.875H24.5V16.125H0.5V7.875Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.5 0H24.5V7.875H0.5V0Z" fill="#7AA251" />
    </svg>
  )
}
