import React from 'react'
import classnames from 'classnames'

import classes from './styles.module.scss'

export const Highlights = ({ items = [], isActive }) => {
  if (items.length === 0) return null

  return (
    <div className={classnames(classes.highlightsContainer, { [classes.hide]: !isActive })}>
      <ul className={classes.wrapper}>
        {items
          .map((item, index) => {
            const elements = []

            if (index > 0) {
              elements.push(
                <li className={classnames(classes.item, classes.spacer)} key={'spacer' + index}>
                  <div className={classes.key}>|</div>
                </li>
              )
            }

            elements.push(
              <li className={classes.item} key={item.key} data-test-id={'highlight-data-point-' + index}>
                <div className={classes.key}>{item.key}</div>
                <div className={classes.value}>{item.value}</div>
              </li>
            )

            return elements
          })
          .flat()}
      </ul>
    </div>
  )
}

export default Highlights
