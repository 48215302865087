import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import { ScrollDownIcon } from 'components/SVG'

import classes from './styles.module.scss'

export const SectionScroller = ({
  sectionId = '',
  mobileCopy = '',
  tabletCopy = '',
  desktopCopy = '',
  copyAlign = 'left',
  color = 'dark',
}) => {
  const [copy, setCopy] = useState('')

  const scrollToSection = () => {
    const section = document.getElementById(sectionId)

    if (!section) return

    section.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    const onResize = () => {
      const width = window.innerWidth

      if (width < 768) {
        setCopy(mobileCopy)
      } else if (width < 1024) {
        setCopy(tabletCopy)
      } else {
        setCopy(desktopCopy)
      }
    }

    onResize()

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.sectionScrollerContainer} onClick={scrollToSection}>
      <div
        className={classnames(classes.wrapper, {
          [classes.white]: color === 'white',
          [classes.alignTop]: copyAlign === 'top',
        })}
      >
        {copy ? <span className={classes.copy}>{copy}</span> : null}
        <div className={classes.svg}>
          <ScrollDownIcon />
        </div>
      </div>
    </div>
  )
}

export default SectionScroller
