import React, { useState, useEffect } from 'react'

import API from 'api'

import classes from './styles.module.scss'

export const Avatar = ({ id, hash = '' }) => {
  const [data, setData] = useState()

  useEffect(() => {
    API.getSignedUrl(id, hash).then(data => {
      setData(data.content)
    })
  }, [id, hash])

  return (
    <div className={classes.avatarContainer}>
      <img src={data} alt="Avatar" />
    </div>
  )
}

export default Avatar
