import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Search, Button, Heading, Paragraph, SectionScroller, NotFoundMSG, Footer } from 'components'
import { getDiamondAction } from 'store/actions'
import { selectSearchId } from 'store/selectors'
import { testIds } from 'config'

import classes from './styles.module.scss'

const LandingPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const gemfairRef = useRef()
  const searchId = useSelector(selectSearchId)
  const [isNotFoundTop, setIsNotFoundTop] = useState(false)
  const [isNotFoundBottom, setIsNotFoundBottom] = useState(false)
  const testId = testIds.landingPage

  const handleSearchSubmit = (value, section) => {
    dispatch(getDiamondAction(value))
      .then((data) => {
        if (!data) {
          if (section === 'top') {
            setIsNotFoundTop(true)
          } else {
            setIsNotFoundBottom(true)
          }
        } else {
          navigate(`/diamond/${value}`)
        }
      })
      .catch(() => {
        if (section === 'top') {
          setIsNotFoundTop(true)
        } else {
          setIsNotFoundBottom(true)
        }
      })
  }

  const handleSearchClear = (section) => {
    if (section === 'top') {
      setIsNotFoundTop(false)
    } else {
      setIsNotFoundBottom(false)
    }
  }

  return (
    <div className={classes.landingPageContainer}>
      <header data-test-id={testId.header}>
        <div className={classes.overlay}></div>
        <div className={classes.wrapper}>
          <div className={classes.logo}>
            <a href="/">
              <img src={'/images/logos/gemfair.svg'} alt="Gemfair logo" />
            </a>
          </div>
          <Heading type="h1" className={classes.heading}>
            Discover your Diamond’s story
          </Heading>
          <div className={classes.search}>
            <div className={classes.positioner}>
              <Search
                isInvalid={isNotFoundTop}
                wrapperClassName={classes.searchWrapper}
                testIdPrefx={testId.searchTop}
                onSubmit={(value) => handleSearchSubmit(value, 'top')}
                onClear={() => handleSearchClear('top')}
              >
                {!isNotFoundTop ? (
                  <Heading type="h4" className={classes.secondaryHeading}>
                    GemFair Diamond Search enables you to follow the journey of your diamond from source. <br /> Add
                    your TracrID above and click the search button to learn more.
                  </Heading>
                ) : (
                  <NotFoundMSG searchId={searchId} />
                )}
              </Search>
            </div>
          </div>

          <div className={classes.sectionScroller} data-test-id={testId.sectionScoller}>
            <SectionScroller
              sectionId="gemfair"
              mobileCopy="Discover our story"
              tabletCopy="Discover our story"
              desktopCopy="Discover our story"
            />
          </div>
        </div>
      </header>
      <div id="gemfair" className={classes.gemfairSection} ref={gemfairRef} data-test-id={testId.section}>
        <div className={classes.overlay}></div>
        <div className={classes.wrapper}>
          <div className={classes.positioner}>
            <Heading className={classes.heading} type="h3" color="white">
              GemFair
            </Heading>
            <Paragraph className={classes.copy}>
              GemFair is a De Beers Group programme that provides a secure and transparent route to market for ethically
              sourced artisanal and small-scale mined diamonds from Sierra Leone. The programme, which is based upon
              international best practice standards, assures that GemFair’s suppliers’ rights are respected, that miners
              receive fair value for their diamonds, and that the artisanal sector’s potential to contribute to
              sustainable economic development within mining communities is maximised.
            </Paragraph>

            <div className={classes.button}>
              <Button href="https://gemfair.com/" isExternal>
                Learn more
              </Button>
            </div>
          </div>
          <div className={classes.sectionScroller} data-test-id={testId.sectionScoller}>
            <SectionScroller sectionId="solution" tabletCopy="Our Solution" desktopCopy="Our Solution" color="white" />
          </div>
        </div>
      </div>
      <div id="solution" className={classes.solutionSection} data-test-id={testId.section}>
        <div className={classes.overlay}></div>
        <div className={classes.wrapper}>
          <div className={classes.positioner}>
            <Heading className={classes.heading} type="h3" color="white">
              Our Solution
            </Heading>
            <Paragraph className={classes.copy}>
              Our solution combines on the ground monitoring of standards and ethical practices with digital innovation
              to ensure that we purchase diamonds only from trusted sources. To promote inclusion, we work closely with
              miners to build their capacity to meet our standards and continuously improve their operations. In doing
              so we are uplifting standards across the artisanal and small-scale diamond mining sector.
            </Paragraph>
            <div className={classes.button}>
              <Button href="https://www.gemfair.com/" isExternal>
                GEMFAIR
              </Button>
            </div>
          </div>
          <div className={classes.sectionScroller} data-test-id={testId.sectionScoller}>
            <SectionScroller sectionId="tracr" tabletCopy="Tracr" desktopCopy="Tracr" color="white" />
          </div>
        </div>
      </div>
      <div id="tracr" className={classes.tracrSection} data-test-id={testId.section}>
        <div className={classes.overlay}></div>
        <div className={classes.wrapper}>
          <div className={classes.positioner}>
            <div className={classes.logo}>
              <img src={'/images/logos/tracr_round.svg'} alt="Tracr logo" />
            </div>
            <div className={classes.content}>
              <Heading className={classes.heading} type="h3" color="white">
                Tracr
              </Heading>
              <Paragraph className={classes.copy}>
                Tracr is an advanced technology platform developed by De Beers Group to underpin trust in diamond
                provenance. Using leading-edge technologies, including blockchain, artificial intelligence, the internet
                of things and advanced security and privacy technologies, Tracr enables participants to register
                provenance data and enhance confidence in a diamond's source throughout the diamond value chain.
              </Paragraph>
              <div className={classes.button}>
                <Button href="https://www.tracr.com/" isExternal>
                  Learn about Tracr
                </Button>
              </div>
            </div>
          </div>
          <div className={classes.sectionScroller} data-test-id={testId.sectionScoller}>
            <SectionScroller
              sectionId="partnership"
              tabletCopy="The Partnership"
              desktopCopy="The Partnership"
              color="white"
            />
          </div>
        </div>
      </div>

      <div id="partnership" className={classes.partnershipSection} data-test-id={testId.section}>
        <div className={classes.overlay}></div>
        <div className={classes.wrapper}>
          <div className={classes.positioner}>
            <Heading className={classes.heading} type="h3" color="white">
              The Partnership
            </Heading>
            <Paragraph className={classes.copy}>
              GemFair and Tracr have launched an innovative partnership, which allows us to trace artisanally-mined
              diamonds from the mine where they were discovered all the way to our clients. By combining an
              industry-leading assurance programme and a diamond tracking toolkit with cutting-edge blockchain
              technology, our clients can be sure they are contributing directly to positive social and environmental
              impact when they buy our diamonds.
            </Paragraph>
          </div>
          <div className={classes.sectionScroller} data-test-id={testId.sectionScoller}>
            <SectionScroller sectionId="discover" color="white" />
          </div>
        </div>
      </div>

      <div id="discover" className={classes.discoverSection} data-test-id={testId.section}>
        <div className={classes.overlay}></div>
        <div className={classes.wrapper}>
          <Heading className={classes.heading} type="h2">
            Discover your Diamond’s story
          </Heading>
          <div className={classes.search}>
            <div className={classes.positioner}>
              <Search
                isInvalid={isNotFoundBottom}
                testIdPrefx={testId.searchBottom}
                onSubmit={(value) => handleSearchSubmit(value, 'bottom')}
                onClear={() => handleSearchClear('bottom')}
              />
            </div>
            {isNotFoundBottom ? <NotFoundMSG className={classes.message} searchId={searchId} /> : null}
          </div>
        </div>
      </div>
      <Footer testIdPrefix={testId.footer} />
    </div>
  )
}

export default LandingPage
