import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Search, Footer, NotFoundMSG } from 'components'
import { getDiamondAction } from 'store/actions'
import { selectSearchId } from 'store/selectors'

import classes from './styles.module.scss'

export const DiamondNotFound = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const searchId = useSelector(selectSearchId)
  const [isInvalid, setIsInvalid] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const handleSearchSubmit = (value) => {
    setIsInvalid(false)
    setIsLoading(true)

    dispatch(getDiamondAction(value))
      .then((data) => {
        setIsLoading(false)

        if (!data) setIsInvalid(true)
        else navigate(`/diamond/${value}`)
      })
      .catch(() => {
        setIsLoading(false)
        setIsInvalid(true)
      })
  }

  return (
    <div className={classes.diamondNotFoundContainer}>
      <header>
        <div className={classes.overlay}></div>
        <div className={classes.wrapper}>
          <div className={classes.logo}>
            <a href="/">
              <img src={'/images/logos/gemfair_white.svg'} alt="Gemfair logo" />
            </a>
          </div>
          <div className={classes.search}>
            <Search value={searchId} theme="dark" isInvalid={isInvalid} onSubmit={handleSearchSubmit} />
          </div>
          {!isLoading ? (
            <div className={classes.notFoundMSG}>
              <NotFoundMSG searchId={searchId} theme="light" />
            </div>
          ) : null}
        </div>
      </header>
      <Footer />
    </div>
  )
}

export default DiamondNotFound
