import LandingPage from 'pages/Landing'
import DiamondPage from 'pages/Diamond'
import DiamondNotFound from 'pages/DiamondNotFound'
import { DefaultLayout } from 'containers/Layouts'
import localization from 'localization/en.json'

const copy = localization.routes

const routes = [
  {
    path: '/',
    element: <DefaultLayout />,
    children: [
      { title: copy.landing, index: true, element: <LandingPage /> },
      {
        title: copy.diamondNotFound,
        path: '/diamond/not-found',
        element: <DiamondNotFound />,
      },
      {
        title: copy.diamondView,
        path: '/diamond/:id',
        element: <DiamondPage />,
      },
    ],
  },
]

export default routes
