class DB {
  constructor() {
    // 7 days
    this.CLEAR_INTERVAL = 7 * 1000 * 60 * 60 * 24;

    const openRequest = (this.openRequest = indexedDB.open("gemfair_media", 2));

    this.openRequest.onupgradeneeded = function (e) {
      const db = openRequest.result;

      if (!db.objectStoreNames.contains("assets")) {
        db.createObjectStore("assets");
      }

      if (!db.objectStoreNames.contains("modified")) {
        db.createObjectStore("modified");
      }

      // console.log('initialized database')
    };

    this.openRequest.onerror = function () {
      console.error("Error opening database", openRequest.error);
    };

    this.openRequest.onsuccess = () => {
      this.db = openRequest.result;
      this.openRequest = null;

      /**
       * Following logic clears the database once every this.CLEAR_INTERVAL
       */
      new Promise((resolve, reject) => {
        const transaction = this.db.transaction("modified", "readwrite");

        const modified = transaction.objectStore("modified");

        const request = modified.get("date");

        request.onsuccess = function () {
          resolve(request.result);
        };

        request.onerror = function () {
          console.log("Error finding date", request.error);

          reject(request.error);
        };
      }).then((date) => {
        // days passed are less than CLEAR_INTERVAL
        if (Date.now() - new Date(date) < this.CLEAR_INTERVAL) return;

        new Promise((resolve, reject) => {
          const transaction = this.db.transaction("assets", "readwrite");

          const assets = transaction.objectStore("assets");

          const request = assets.clear();

          request.onsuccess = function () {
            resolve();
          };

          request.onerror = function () {
            console.log("Error clearing db", request.error);

            reject(request.error);
          };
        }).then(() => {
          // update the date
          const transaction = this.db.transaction("modified", "readwrite");

          const modified = transaction.objectStore("modified");

          modified.put(Date.now(), "date");
        });
      });

      // console.log('Database open')
    };
  }

  put(key, file) {
    return new Promise((resolve, reject) => {
      const convertToBuffer = new Promise((resolve, reject) => {
        resolve(file);
      });

      convertToBuffer.then((buffer) => {
        const transaction = this.db.transaction("assets", "readwrite");

        const assets = transaction.objectStore("assets");

        const request = assets.put(buffer, key);

        request.onsuccess = function () {
          resolve();
        };

        request.onerror = function () {
          console.log("Error putting asset", request.error);

          reject(request.error);
        };
      });
    });
  }

  get(key) {
    const readFrom = (resolve, reject) => {
      const transaction = this.db.transaction("assets");

      const assets = transaction.objectStore("assets");

      const request = assets.get(key);

      request.onsuccess = function () {
        resolve(request.result);
      };

      request.onerror = function () {
        console.log("Error finding asset", request.error);

        reject(request.error);
      };
    };

    return new Promise((resolve, reject) => {
      if (!this.db && this.openRequest) {
        this.openRequest.addEventListener("success", () =>
          readFrom(resolve, reject)
        );
      } else {
        readFrom(resolve, reject);
      }
    });
  }

  delete(key) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction("assets", "readwrite");

      const assets = transaction.objectStore("assets");

      const request = assets.delete(key);

      request.onsuccess = function () {
        resolve();
      };

      request.onerror = function () {
        console.log("Error deleting asset", request.error);

        reject(request.error);
      };
    });
  }
}

const db = new DB();

export default db;
