import http from '../utils/http'
// import diamond_mock from './diamond.json'

const namespace = 'diamond'

const getDiamond = (id) => {
  // return Promise.resolve({ content: diamond_mock })
  return http.get(`${namespace}/search/${id}`).then((response) => {
    if (response.status === 200) {
      return response.json()
    } else {
      throw response
    }
  })
}

const getSignedUrl = (id, hash) => {
  return http.get(`${namespace}/asset/${id}/${hash}`, null).then((response) => {
    if (response.status === 200) {
      return response.json()
    } else {
      throw response
    }
  })
}

const diamond = {
  getDiamond,
  getSignedUrl,
}

export default diamond
