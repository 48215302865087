import env from '@beam-australia/react-env'

class Http {
  constructor() {
    console.log('API_URL', env('API_URL'))

    this.API_URL = env('API_URL') || 'https://reg2.qa.internal.tracr.com/api/v1'
    console.log('Actual URL', this.API_URL)
    // fetch('https://reg2.qa.internal.tracr.com/api/v1/diamond/gemfair/asdasda').then(response => response.json()).then(json => console.log("JS", json))
  }

  validateBody(body, format) {
    // let all formats other than json be handled by axios
    if ((format && format !== 'json') || !body) return body

    const parsed = JSON.stringify(body)

    // do not send empty data to BE
    if (parsed.length === 0 || parsed === '{}' || parsed === '[]') return undefined

    return body
  }

  get(url, query) {
    return fetch(`${this.API_URL}/${url}`, {
      method: 'GET',
      params: query,
    })
  }

  post(url, body, format) {
    const validatedBody = this.validateBody(body, format)

    return fetch(`${this.API_URL}/${url}`, {
      method: 'POST',
      body: validatedBody,
    })
  }
}

const http = new Http()

export default http
