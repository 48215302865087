import React from 'react'
import classnames from 'classnames'

import classes from './styles.module.scss'
import HorizontalScroller from 'components/HorizontalScroller'

export const TabSelector = ({ tabs = [], activeIndex = 0, onChange }) => {
  return (
    <div className={classes.tabSelectorContainer}>
      <HorizontalScroller className={classes.padding}>
        <ul className={classes.tabs}>
          {tabs
            .map((tab, index) => {
              const elements = []

              if (index > 0) {
                elements.push(<li className={classes.spacer} key={'spacer' + index}></li>)
              }

              elements.push(
                <li
                  className={classnames(classes.tab, { [classes.active]: index === activeIndex })}
                  key={tab.button_text}
                  onClick={() => {
                    onChange(index)
                  }}
                  data-test-id={'tab-button-' + index}
                >
                  {tab.button_text}
                </li>
              )

              return elements
            })
            .flat()}
        </ul>
      </HorizontalScroller>
    </div>
  )
}

export default TabSelector
